import { Link } from 'gatsby'
import React, { Fragment } from 'react'
import AppSlider from '../components/AppSlider'
import ContactForm from '../components/ContactForm'
import EventVideo from '../components/event/video'
import LandingComposition from '../components/index/composition'
import { NavigationItem, NavPageItem } from '../components/Navigation'
import SEO from '../components/seo/seo'
import Tabs, { Tab } from '../components/Tabs'
import { useLanding } from '../data/landing'
import Block from '../design/Block'
import H from '../design/H'
import Layout from '../layout'

const IndexPage = () => {
  const landing = useLanding()

  const navPages: NavPageItem[] = [{ label: 'Event', path: '/event' }]

  const navItems: NavigationItem[] = [
    // { id: 'event', label: landing.event.nav, path: '/event' },
    { id: 'event', label: landing.event.nav },
    { id: 'showroom', label: landing.showroom.nav },
    // { id: 'team', label: landing.team.nav },
    { id: 'service', label: landing.service.nav },
    { id: 'contact', label: landing.contact.nav },
  ]

  return (
    <Layout navPages={navPages} navItems={navItems}>
      <SEO />
      {/* {landing.welcome.intro}
      <AppSlider /> */}

      {/* SECTION: Welcome */}
      {/* FIXME: overflow-hidden is a temporary fix */}
      <div className="relative z-10 container mx-auto px-4 py-8 md:flex flex-row overflow-hidden">
        <div className="md:w-2/5">
          <H className="max-w-xs lg:max-w-lg">
            {landing.welcome.slogans[0].text}
          </H>
          <p className="max-w-xs my-6 text-xl text-gray-700 leading-relaxed">
            {landing.welcome.intro}
          </p>
          <p className="mt-4 mb-8">
            <a
              href={`mailto:${landing.contact.email}`}
              target="_blank"
              rel="noreferrer noopener"
              className="btn"
            >
              Contact us!
            </a>
          </p>
        </div>
        <div className="md:w-3/5 pointer-events-none">
          <LandingComposition />
        </div>
      </div>

      {/* SECTION: Event */}
      <Block id="event">
        <H className="pt-12" center onDark>
          {landing.event.h}
        </H>
        <div className="py-8 px-4 text-center md:w-4/5 text-lg mx-auto">
          {landing.event.intro}
        </div>
        <div className="pb-8 px-4 text-center">
          <Link to="event" className="btn uppercase tracking">
            Mehr infos
          </Link>
        </div>
        <Tabs>
          <Tab title={landing.videos.tab_1.title}>
            <EventVideo videoId={landing.videos.tab_1.videoId} />
          </Tab>
          <Tab title={landing.videos.tab_2.title}>
            <EventVideo videoId={landing.videos.tab_2.videoId} />
          </Tab>
        </Tabs>
        <div className="h-32"></div>
      </Block>

      {/* SECTION: Showroom */}
      <div id="showroom" className="relative -mt-40">
        <div className="absolute top-0 w-full h-full">
          <svg
            className="absolute bottom-0 -mb-48"
            viewBox="0 0 1440 492"
            // preserveAspectRatio="xMaxYMid slice"
            preserveAspectRatio="none"
          >
            <path
              transform="translate(0 -2514) translate(-6 1660)"
              fill="#50FFB4"
              stroke="none"
              strokeWidth={1}
              fillRule="evenodd"
              d="M1 918.595751L593 854 1485 1178 1451 1346 6 1140.03139z"
            />
          </svg>
        </div>
        <div className="relative min-h-screen">
          <AppSlider title={landing.showroom.h} />
        </div>
      </div>

      {/* SECTION: Spaceeeee */}
      {/* <div className="h-screen"></div> */}

      {/* SECTION: Service */}
      <div id="service" className="container mx-auto mt-64 mb-40 px-4">
        <H center>{landing.service.h}</H>
        <p className="my-6 text-md text-gray-700 leading-relaxed">
          {landing.service.desc}
        </p>

        <div className="lg:grid grid-cols-3 my-12 -mx-4">
          {landing.service.service_list.map(({ service }, i) => (
            <Fragment key={i}>
              <H smallest center className="row-start-1 max-w-lg mx-auto px-4 mt-10 mb-8">
                {service.title}
              </H>
              <div className="row-start-2 max-w-lg mx-auto px-4 flow-root">
                <img
                  className="float-right mt-12 w-20"
                  style={{ shapeOutside: `url(/svgs/service_${i}.svg)` }}
                  src={`/svgs/service_${i}.svg`}
                  alt={`Service Icon for Service ${service.title}`}
                />
                <p className="text-gray-700">{service.body}</p>
              </div>
            </Fragment>
          ))}
        </div>

        <p className="max-w-2xl mt-20 mb-4 text-gray-700 leading-relaxed">
          {landing.service.flyer_desc}
        </p>

        <a
          className="inline-block btn px-8 py-2"
          href={landing.service.flyer.publicURL as string}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="text-xl">{landing.service.flyer_label}</span>
          {/* <div className="text-xs text-secondary">
            <span className="italic mr-10">Klicke zum Öffnen</span>
            <span>{landing.service.flyer.prettySize}</span>
          </div> */}
        </a>
      </div>

      {/* SECTION: About us */}
      {/* <div className="m-64"></div> */}

      {/* SECTION: Team */}
      {/* <Block>
        <H onDark>{landing.team.h}</H>
        {landing.team.members[0].name}
        <Img fixed={landing.team.members[0].photo.childImageSharp.fixed} />
      </Block> */}

      {/* SECTION: Contact */}
      <div id="contact" className="bg-green">
        <div className="container mx-auto px-4 py-16 md:flex flex-row">
          <div className="md:flex-1">
            <H>{landing.contact.h}</H>
            <h2 className="mt-4 uppercase text-orange tracking-wider leading-relaxed font-medium">
              Adresse
            </h2>
            <p className="max-w-xs leading-relaxed whitespace-pre-line">
              {landing.contact.address}
            </p>
            <h2 className="mt-4 uppercase text-orange tracking-wider leading-relaxed font-medium">
              Telefon
            </h2>
            <p className="max-w-xs leading-relaxed whitespace-pre-line">
              {landing.contact.phone}
            </p>
            <h2 className="mt-4 uppercase text-orange tracking-wider leading-relaxed font-medium">
              E-Mail
            </h2>
            <p className="max-w-xs leading-relaxed whitespace-pre-line">
              {landing.contact.email}
            </p>
          </div>
          <div className="mt-16 mb-2 md:flex-1 md:self-end">
            <ContactForm />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
