import React, { FC, SVGProps } from 'react'

const DiceSVG: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg x="0px" y="0px" viewBox="0 0 218.4 288" {...props}>
    <style>{'.dice-svg-st0{fill:none;stroke:#000;stroke-miterlimit:10}'}</style>
    <path
      className="dice-svg-st0"
      d="M104.6 146.6c-.4.5-.7 1.2-.6 1.8.2 1.2 1.6 1.6 2.7 1.8 6.6 1 13.3 1.5 19.9 1.5 2.4-5.1 6.1-9.6 10.6-12.9-4.9-1.5-18.4-8.3-23.2-6.7-3.6 1.4-7.3 11.2-9.4 14.5z"
    />
    <path
      className="dice-svg-st0"
      d="M104.3 148.1c-.3 3.7-.9 11.5.1 15.1.2.8.5 1.5 1 2.1.5.6 1.3 1 2.1 1.4 5.8 2.7 11.9 4.9 18.2 6.4 3.3-5.6 7.8-10.4 13.2-14 .5-.3 1-.6 1.3-1.1.4-.6.4-1.4.4-2.1 0-5.1-2.1-12.3-2.1-17.4M126.1 151c.2 7.2.1 14.5-.1 21.7"
    />
    <path d="M120.2 144.8c4.5 0 4.5-7 0-7s-4.5 7 0 7zM109.3 158.2c3.2 0 3.2-5 0-5s-3.2 5 0 5zM118.5 167.2c3.2 0 3.2-5 0-5s-3.2 5 0 5zM131.2 154.8c2.6 0 2.6-4 0-4s-2.6 4 0 4zM136 147.7c2.6 0 2.6-4 0-4s-2.6 4 0 4zM136.8 157.5c2.6 0 2.6-4 0-4-2.5 0-2.5 4 0 4zM131 162.8c2.6 0 2.6-4 0-4s-2.6 4 0 4z" />
  </svg>
)

export default DiceSVG
