import React, { createRef, useState, useEffect } from 'react'

const EventVideo = ({ videoId }: { videoId: string }) => {
  const [loaded, setLoaded] = useState(false)
  const container = createRef<HTMLDivElement>()

  const isIOSupported = typeof window !== "undefined" && window && 'IntersectionObserver' in window

  const videoObserver = isIOSupported ? new IntersectionObserver((entries) => {
    if (!entries || entries.length <= 0) return;

    if (entries[0].isIntersecting) {
      setLoaded(true);
      videoObserver!.disconnect();
    }
  }, {
    rootMargin: '100px 0px',
    threshold: 0.1
  }) : null;

  useEffect(() => {
    if (isIOSupported && videoObserver) {
      if (container && container.current) {
        videoObserver.observe(container.current);
      }
    } else {
      setLoaded(true);
    }

    return () => { if (isIOSupported && videoObserver) videoObserver.disconnect() }
  }, [container]);

  return (
    <div ref={container} className="relative pt-9/16">
      {loaded && <iframe
        className="absolute top-0 w-full h-full"
        src={`https://www.youtube-nocookie.com/embed/${videoId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>}
    </div>
  )
}

export default EventVideo
