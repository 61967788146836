import React, { FC, SVGProps } from 'react'

const PhoneSVG: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg x="0px" y="0px" viewBox="0 0 218.4 288" {...props}>
    <style>
      {
        '.phone-svg-st0{fill:#fff;stroke:#000;stroke-width:2;stroke-miterlimit:10}'
      }
    </style>
    <path
      d="M168.4 66.8l-21.3-2-38.7-.7-10.7-.7-14.7.7-5 6.7s-7 142-7 154 5.3 10.7 5.3 10.7l80 3.3 6.7-3.3 4-18s2.7-128 4-140.7c1.4-12.7-2.6-10-2.6-10zm-11 144.1l-4.2 5.4-12.2.5-8.9-.5-32.2-.5-17.8-1.6s-3.3 2.2-2.2-8.1c1.1-10.2 3.4-113.6 3.4-113.6L86.6 78l5.6-2.7 66.6 2.7s4.4-1.1 4.4 8.6c0 9.6-5.8 124.3-5.8 124.3z"
      fill="#fff"
      stroke="#000"
      strokeWidth={2.461}
      strokeMiterlimit={10}
    />
    <path className="phone-svg-st0" d="M111.2 70.4L129.4 71.9" />
    <path className="phone-svg-st0" d="M137.6 70.6L137.7 72.4 139.1 72.2" />
    <path
      d="M115.9 222.9l-3.8 2 .4 3.8 3 1 6.2.5s.8-.1.8-.9.8-5.2.8-5.2l-1.8-.8-1.8-.9-3.8.5z"
      fill="#fff"
      stroke="#000"
      strokeWidth={2.4793}
      strokeMiterlimit={10}
    />
  </svg>
)

export default PhoneSVG
