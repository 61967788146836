import React, { FC, SVGProps } from 'react'

const PlaystoneSVG: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg x="0px" y="0px" viewBox="0 0 218.4 288" {...props}>
    <style>
      {'.playstone-svg-st0{fill:none;stroke:#000;stroke-miterlimit:10}'}
    </style>
    <path
      className="playstone-svg-st0"
      d="M117 139.9c.3-.6.9-1 1.6-1.3 1.2-.6 2.8-.7 4-.2s2.2 2 2 3.3-1.6 2.5-3 2.2c1.1.1 2.2.6 3 1.4-.6.4-1.4.5-2.1.2 1.1 4.5 3.2 8.7 6.2 12.3.8 1 1.8 2 3.1 2.1.2.7-.5 1.5-1.2 1.3.4.6.8 1.2 1.2 1.9-5.5.6-11 1.2-16.6 1.7-.8.1-1.6.2-2.3-.1-.7-.3-1.3-1-1.1-1.8h1.2c-.7.1-1.3-.9-.8-1.5.9.1 1.3-.9 1.5-1.8l2.4-13.5c-.5-.5-1.5-.2-1.8-.9-.4-.7.7-1.3 1.5-1.2.7.1 1.3.1 1.8-.3-.5-.9-1.3-2.6-.6-3.8zM120.6 140.1c.9.3 1.3 1.5.7 2.2M114.7 162.4c4.3.3 8.6-.2 12.6-1.5"
    />
  </svg>
)

export default PlaystoneSVG
