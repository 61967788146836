import { graphql, useStaticQuery } from 'gatsby'
import { FixedObject, FluidObject } from 'gatsby-image'
import { ConnectionQueryData } from './type'

export interface App {
  id: string
  icon: { childImageSharp: { fixed: FixedObject } }
  title: string
  description: string
  link_ios: string
  link_android: string
  image?: { childImageSharp: { fluid: FluidObject } }
}

interface TData {
  allAppsYaml: ConnectionQueryData<App>
}

const appsQuery = graphql`
  query AppsQuery {
    allAppsYaml {
      edges {
        node {
          id
          de {
            icon {
              childImageSharp {
                fixed(width: 96, height: 96) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            title
            description
            link_ios
            link_android
            image {
              childImageSharp {
                fluid(
                  maxWidth: 400
                  maxHeight: 800
                  fit: CONTAIN
                  background: "rgba(0,0,0,0)"
                ) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export const useApps = () =>
  useStaticQuery<TData>(appsQuery).allAppsYaml.edges.map((e) => ({ id: e.node.id, ...(e.node as any).de }))
