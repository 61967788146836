import React, { FC } from 'react'

const Block: FC<{ id?: string }> = ({ id, children }) => (
  <div id={id} className="relative">
    {/* Background block */}
    <div className="absolute top-0 w-full h-full">
      <svg
        className="w-full h-full -mt-32"
        viewBox="0 0 1440 1374"
        preserveAspectRatio="none"
      >
        <path
          transform="translate(0 -605) translate(-842 605)"
          fill="#FF6750"
          stroke="none"
          strokeWidth={1}
          fillRule="evenodd"
          d="M838 218.918975L2282 2.27373675e-13 2282 1213.28724 1331.5 1192 838.020681 1375.38354z"
        />
      </svg>
    </div>
    {/* Content block */}
    <div className="relative container mx-auto px-4 pb-48 md:pb-56 lg:pb-64">
      {children}
    </div>
  </div>
)

export default Block
