import { graphql, useStaticQuery } from 'gatsby'
import { FixedObject } from 'gatsby-image'
import { FileSystemNode } from 'gatsby-source-filesystem'
import { VideoTabbar } from './type'

interface Landing {
  welcome: LandingWelcome
  event: LandingEvent
  videos: VideoTabbar
  showroom: LandingShowroom
  service: LandingService
  team: LandingTeam
  contact: LandingContact
}

interface LandingWelcome {
  intro: string
  slogans: { text: string }[]
}
interface LandingEvent {
  h: string
  nav: string
  intro: string
}
interface LandingShowroom {
  h: string
  nav: string
}
interface LandingService {
  h: string
  nav: string
  desc: string
  service_list: {
    service: {
      title: string
      body: string
    }
  }[]
  flyer_desc: string
  flyer_label: string
  flyer: Pick<FileSystemNode, 'publicURL' | 'prettySize' | 'extension'>
}
interface LandingTeam {
  h: string
  nav: string
  members: {
    name: string
    position: string
    photo: { childImageSharp: { fixed: FixedObject } }
  }[]
}
interface LandingContact {
  h: string
  nav: string
  address: string
  phone: string
  email: string
  github?: string
  instagram?: string
  facebook?: string
  lat?: number
  lng?: number
}

interface TData {
  landingYaml: { [lang: string]: Landing }
}

export const landingQuery = graphql`
  query LandingQuery {
    landingYaml {
      de {
        welcome {
          intro
          slogans {
            text
          }
        }
        event {
          h
          nav
          intro
        }
        videos {
          tab_1 {
            title
            videoId
          }
          tab_2 {
            title
            videoId
          }
        }
        showroom {
          h
          nav
        }
        service {
          h
          nav
          desc
          service_list {
            service {
              title
              body
            }
          }
          flyer_desc
          flyer_label
          flyer {
            publicURL
            prettySize
            extension
          }
        }
        team {
          h
          nav
          members {
            name
            photo {
              childImageSharp {
                fixed(width: 200, height: 200) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            position
          }
        }
        contact {
          h
          nav
          address
          email
          phone
          lat
          lng
        }
      }
    }
  }
`

export const useLanding = () => useStaticQuery<TData>(landingQuery).landingYaml.de
