import React from 'react'
import CardSVG from './card.svg'
import './composition.css'
import DiceSVG from './dice.svg'
import PhoneSVG from './phone.svg'
import PlaystoneSVG from './playstone.svg'

const LandingComposition = () => (
  <div className="relative z-40 w-full pb-2/3">
    <PlaystoneSVG className="comp-playstone absolute w-full h-full" />
    <DiceSVG className="comp-dice absolute w-full h-full" />
    <CardSVG className="comp-card absolute w-full h-full" />
    <PhoneSVG className="comp-phone absolute w-full h-full" />
  </div>
)

export default LandingComposition
