import { graphql, useStaticQuery } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import React, { FC } from 'react'

const badgeImageQuery = graphql`
  query AppStoreBadgeImageQuery {
    file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "app-store-badge.png" }
    ) {
      childImageSharp {
        fixed(height: 52) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

const AppStoreBadge: FC<{ link: string }> = ({ link }) => {
  const img = useStaticQuery(badgeImageQuery).file.childImageSharp.fixed

  return (
    <a className="px-2" href={link} target="_blank" rel="noopener noreferrer">
      <GatsbyImage fixed={img} alt="Laden im App Store" />
    </a>
  )
}

export default AppStoreBadge
