import React, { FC, useState } from 'react'

export interface TabProps {
    title: string
}

export interface TabsProps {
    children?: React.ReactElement<TabProps>[] | React.ReactElement<TabProps>
}

const Tabs = ({ children }: TabsProps) => {
    const [currentTab, setCurrentTab] = useState(0);

    const childrenArray = Array.isArray(children) ? children : (typeof children === "undefined" ? [] : [children])

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        // check for illegal children
        childrenArray.filter(t => t.type != Tab).forEach(t => {
            console.error("Illegal child in `Tabs` component. Only `Tab`s allowed.", t)
        })
    }

    const tabs = childrenArray.filter(t => t.type == Tab)
    if (tabs.length < 1) {
        console.warn("Empty `Tabs` component.")
        return null
    }

    const tabTitles = tabs.map((t, i) => {
        return <span key={i} className={`cursor-default px-6 py-2 rounded-full${i==currentTab?' bg-white' : ''}`} onClick={() => setCurrentTab(i)}>
            {t.props.title}
        </span>
    })

    const tabContent = tabs[currentTab]

    return (
        <>
            <div className="mt-12 mb-8 text-center">
                <div className="inline-flex flex-wrap justify-around rounded-full bg-white bg-opacity-50">
                    {tabTitles}
                </div>
            </div>
            {tabContent}
        </>
    )
}

export const Tab: FC<TabProps> = ({ children }) => <>{children}</>

export default Tabs
