import React, { FC, SVGProps } from 'react'

const CardSVG: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg x="0px" y="0px" viewBox="0 0 218.4 288" {...props}>
    <style>{'.card-svg-st0{fill:none;stroke:#000;stroke-miterlimit:10}'}</style>
    <path
      className="card-svg-st0"
      d="M100.4 190.6c14.6-1 29.2-1.9 43.8-2.9-.5-4.8-.9-9.6-1.4-14.3-1.9-19.5-3.8-39.1-1.9-58.6-7.1 1-14.3-1.3-21.5-1.2-3.8 0-7.5.7-11.1 1.8-3.8 1.2-8.6.8-9.1 5.5-.6 5.5-.1 11.4 0 17 .1 11.4.2 22.8.6 34.2 0 1.4.7 18.5.6 18.5z"
    />
    <path d="M114.9 149.2c2.7 2.3 2.6 6.6 5.1 9.1-.3-2.3.9-4.5 2-6.5l2.4-4.5c-.6-1.6-2.8-6.7-4.9-5.9-.6.2-4.5 7.9-4.6 7.8zM106.6 134.5c-.2.3-.4.6-.3 1 0 .3.2.5.3.7.9 1.3 2 2.6 3.1 3.8 0-2.9 1.9-5.4 3.7-7.6.2-.2-3.7-4.1-4.1-4.4-1.4 1.5-1.6 4.6-2.7 6.5zM129.4 167.3c1.3 3 3 5.7 5.1 8.1.9-3.9 1.7-7.7 2.4-11.6 0-.3.1-.6 0-.8-.4-2.1-4-5.1-5.9-3.6-1.6 1.2-2.4 6.2-1.6 7.9z" />
    <path
      className="card-svg-st0"
      d="M131.9 119.1c1.1.5 2.3 1.6 2.1 2.8-.2 1-1.4 1.7-2.3 1.3 1.6-.1 3 1.7 2.5 3.3-1.2.2-2.4.4-3.7.6M106.2 180.9c-.6-.3-1.5.1-1.6.8-.2.7.3 1.4.9 1.7.6.3 1.4.3 2 0-.9 0-1.8.4-2.3 1.1-.5.7-.5 1.8.1 2.4.6.6 1.8.6 2.3-.1"
    />
  </svg>
)

export default CardSVG
