import classnames from 'classnames'
import Img from 'gatsby-image'
import React, {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  FC,
  ReactElement,
  SVGProps,
  useCallback,
  useMemo,
  useState,
} from 'react'
import { App, useApps } from '../data/apps'
import H from '../design/H'
import AppStoreBadge from './badges/app-store'
import GooglePlayBadge from './badges/google-play'

const IconArrowLeft = ({
  className,
  ...props
}: Omit<SVGProps<SVGSVGElement>, 'viewBox'>) => (
  <svg
    className={classnames('fill-current', className)}
    width="13px"
    height="26px"
    viewBox="0 0 13 26"
    {...props}
  >
    <path
      transform="translate(-39 -1777) translate(-985 1301) translate(985 441)"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
      d="M51.9266212 60.1604519L39.4376191 47.6748252 51.9266212 35.1891984z"
    />
  </svg>
)
const IconArrowRight = ({
  className,
  ...props
}: Omit<SVGProps<SVGSVGElement>, 'viewBox'>) => (
  <svg
    className={classnames('fill-current', className)}
    width="13px"
    height="26px"
    viewBox="0 0 13 26"
    {...props}
  >
    <path
      transform="translate(-373 -1777) translate(-985 1301) translate(985 441)"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
      d="M373.377133 60.1604519L385.866135 47.6748252 373.377133 35.1891984z"
    />
  </svg>
)

const IconButton: FC<
  { icon: ReactElement } & DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
> = ({ icon, className, ...props }) => (
  <button
    className={classnames(
      'rounded-full w-12 h-12 flex justify-center items-center focus:outline-none focus:ring',
      className
    )}
    {...props}
  >
    {icon}
  </button>
)

const AppIcon: FC<{
  onTap: () => void
  icon: App['icon']
  active?: boolean
  alt: string
}> = ({ icon, active, alt, onTap }) => {
  const transition = `transform 0.2s cubic-bezier(0.18, 0.89, 0.32, 1.28) 0s`
  const innerBorder = active ? 'border-white border-2' : 'border-green border-2'
  const scale = active ? `scale(1)` : `scale(0.8)`

  return (
    <div
      className={`m-2 cursor-pointer`}
      style={{ transform: scale, transition: transition }}
      onClick={onTap}
    >
      <Img
        className={`rounded-lg`}
        style={{ display: 'block' }}
        alt={alt}
        fixed={icon.childImageSharp.fixed}
      />
    </div>
  )
}

const AppsSection: FC<{ title: string }> = ({ title }) => {
  const apps = useApps()
  const [currentAppIdx, setCurrentAppIdx] = useState(0)

  const normalizeIdx = (i: number) => {
    const n = i % apps.length
    return n < 0 ? n + apps.length : n
  }

  const currentApp = useMemo(() => apps[normalizeIdx(currentAppIdx)], [
    currentAppIdx,
  ])

  // 5 slots with the id's to display the app icons
  // [hidden] – prev – current - next - [hidden]
  const appsStencil = [-2, -1, 0, 1, 2]

  const withCarousel = apps.length >= 3

  const moveIdx = useCallback(
    (s) => {
      setCurrentAppIdx(currentAppIdx + s)
    },
    [currentAppIdx, setCurrentAppIdx]
  )

  const next = useCallback(() => {
    setCurrentAppIdx(currentAppIdx + 1)
  }, [currentAppIdx, setCurrentAppIdx])
  const back = useCallback(() => {
    setCurrentAppIdx(currentAppIdx - 1)
  }, [currentAppIdx, setCurrentAppIdx])

  const slider = withCarousel ? (
    <>
      {appsStencil.map((idx, i) => {
        const app = apps[normalizeIdx(currentAppIdx + idx)]
        const key = currentAppIdx + i

        if (i === 0 || i === 4) return null

        return (
          <AppIcon
            key={`${app.id}-${key}`}
            icon={app.icon}
            alt={`${app.title} icon`}
            active={idx === 0}
            onTap={() => moveIdx(idx)}
          />
        )
      })}
    </>
  ) : (
    <>
      {apps.map((app, i) => (
        <AppIcon
          key={app.id}
          icon={app.icon}
          alt={`${app.title} icon`}
          active={currentAppIdx === i}
          onTap={() => setCurrentAppIdx(i)}
        />
      ))}
    </>
  )

  const rightColumn = (
    <>
      <div className="flex flex-row justify-center items-center my-8">
        <IconButton
          className="text-blue"
          icon={<IconArrowLeft />}
          onClick={back}
          aria-label="Vorhergehende App"
        />
        {slider}
        <IconButton
          className="text-blue"
          icon={<IconArrowRight />}
          onClick={next}
          aria-label="Nächste App"
        />
      </div>
      <h2 className="font-display text-center text-2xl tracking-widest">
        {currentApp.title}
      </h2>
      <p className="text-center max-w-md mx-auto py-4">
        {currentApp.description}
      </p>
      <div className="flex flex-direction justify-center">
        {currentApp.link_android ? (
          <GooglePlayBadge link={currentApp.link_android} />
        ) : null}
        {currentApp.link_ios ? (
          <AppStoreBadge link={currentApp.link_ios} />
        ) : null}
      </div>
    </>
  )

  return (
    <div className="relative px-4 pt-8 pb-24 lg:flex lg:flex-row-reverse">
      <div className="lg:flex-1">
        <H center>{title}</H>
        {rightColumn}
      </div>
      <div className="lg:flex-1">
        <div className="relative">
          <div className="absolute top-0 w-full h-full">
            <svg
              className="mx-12 -mt-30 w-full"
              viewBox="0 0 477 574"
              style={{ transform: 'scale(0.7)' }}
            >
              <defs>
                <linearGradient
                  x1="99.9254428%"
                  y1="27.9368319%"
                  x2="19.3128315%"
                  y2="66.0604631%"
                  id="a"
                >
                  <stop stopColor="#CFFFEA" offset="0%" />
                  <stop stopColor="#50FFB4" offset="100%" />
                </linearGradient>
              </defs>
              <path
                transform="translate(-159 -1956) translate(-6 1660)"
                fill="url(#a)"
                fillRule="nonzero"
                stroke="none"
                strokeWidth={1}
                d="M165.979319 415.820791L165.979319 870 641.979319 771.5 641.979319 296z"
              />
            </svg>
          </div>
          <div className="relative">
            {currentApp.image ? (
              <Img
                className={`max-w-sm mx-auto`}
                style={{ display: 'block' }}
                alt={`App Screenshot of ${currentApp.title}`}
                fluid={currentApp.image.childImageSharp.fluid}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AppsSection
